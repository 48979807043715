import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Web3ModalProvider from './contexts/Web3ModalProvider';
import Web3WrapperProvider from './contexts/Web3WrapperProvider/Web3WrapperProvider';

ReactDOM.render(
  <React.StrictMode>
    <Web3ModalProvider>
      <Web3WrapperProvider>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </Web3WrapperProvider>
    </Web3ModalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
